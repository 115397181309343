/**
 * HELPER LIBRARY TO HANDLE ERRORS
 */

import _ from 'lodash';

const UNEXPECTED_ERROR_MESSAGE = 'Unexpected error occurred.';

/**
 * get error message from error object
 * @param error {*}
 * @return {string}
 */
export const getErrorMessage = (error) => {

  const errorType = typeof error;
  switch (errorType) {
    case 'string':
      return error || UNEXPECTED_ERROR_MESSAGE;
    case 'object':
// eslint-disable-next-line  no-case-declarations      
let errorMessage = _.get(error, 'response.data.message')
        || _.get(error, 'data.error')
        || _.get(error, 'data.message')
        || _.get(error, 'response.message')
        || _.get(error, 'response.error')
        || _.get(error, 'response.data')
        || _.get(error, 'message')
        || _.get(error, 'error')
        || _.get(error, 'data')
        || UNEXPECTED_ERROR_MESSAGE;
      return (typeof errorMessage === 'string') ? errorMessage : UNEXPECTED_ERROR_MESSAGE;
    default:
      return UNEXPECTED_ERROR_MESSAGE;
  }
};

/**
 * catch error thrown and dispatch error with error message
 * @param error {*}
 * @param dispatch {function}
 */
export const handleError = (error) => { // , dispatch
  console.error(`Error caught: `, error);
  const errorMessage = getErrorMessage(error);

  return errorMessage;
  // dispatch(setError(errorMessage));
};



import ListsApi from '../../api/lists'
import { showErrorMessage, showSuccessMessage } from '@/utils/Notification';
import { handleError } from '@/utils/ErrorHandler';
import MLService from '@/services/MLService';
import { isUat } from '@/services/EnvService';

// initial state
const state = () => ({
  list: {
  },
  loading: false,
  loadingDetails: false,
  lists: undefined,
  saveLoading: false,
});


// getters
const getters = {
  getFeedIdFromListings(state) {
    const { lists } = state;
    if (lists && lists.length) return lists[0].feed_id;

    if (isUat()) return process.env.VUE_APP_ADV_SEARCH_DEMO_FEED_ID;

    return '';
  }
};

// actions
const actions = {

  async archive({commit}, listId){
    try {
      await MLService.changeArchiveListStatus(listId, true);
      commit('archive', listId);
    } catch(e){
      //
    }
  },

  async unArchive({commit}, listId){
    try {
      await MLService.changeArchiveListStatus(listId, false);
      commit('unArchive', listId);
    } catch(e){
      //
    }
  },

  async loadListsByAgentId({ commit, rootGetters }) {
    commit('setLoading', true);

    const agentExtId = rootGetters['Agent/getAgentExternalId'];
    commit('setLists', undefined);

    // run api call
    if (agentExtId) {
      try {
        let lists = await MLService.loadListsByAgentId(agentExtId);
        
        if ( process.env.VUE_APP_DEMO_FEED_ID ) {
          lists = lists.filter(_=>( _.feed_id == process.env.VUE_APP_DEMO_FEED_ID ));
        }
        commit('setLists', lists);
      }catch(e){
        showErrorMessage('Load failed')
        commit('setLists', []);
      }
    }

    commit('setLoading', false);
  },

  async loadListDetails({ state: { lists }, commit }, {feedId, listingId}) {
    if (!lists) return {};

    commit('setList', {});

    const list = lists.find(({ dbc_listing_id: _id }) => _id === listingId);

    if (list) {
      commit('setList', list || {});
      try {
        commit('setLoadingDetails', true);
        const { description = '' } = await MLService.loadDetails(listingId, feedId);

        if (description) {
          list.description = description;
          commit('setList', list || {});
        }
      } catch (e) {
        // showErrorMessage('List Details Api Error. Cant Load list details');
      }finally {
        commit('setLoadingDetails', false);
      }
    }
  },

  async archiveList({ dispatch }, id) {
    const result = await dispatch('updateListData', { list: { id, is_archived: 1 }, suppressMessage: true });
    result && showSuccessMessage('List was Archived Successfully');
    return result;
  },


  async updateListData({ commit }, { list, suppressMessage = false }) {

    commit('setSaveLoading', true);
    // run api call
    try {
      const updatedList = await ListsApi.updateListDataById(list.id, list);
      commit('setList', updatedList);
      !suppressMessage && showSuccessMessage('List has been successfully Saved');
      return true;
    } catch (e) {
      showErrorMessage(handleError(e));
      return false;
    } finally {
      commit('setSaveLoading', false);
    }
  },

};

// mutations
const mutations = {
  setLists(state, newListsData) {
    state.lists = newListsData ? [...newListsData] : undefined;
  },
  setList(state, newList){
    state.list = newList;
  },
  setLoading(state, status) {
    state.loading = status;
  },
  setLoadingDetails(state, status) {
    state.loadingDetails = status;
  },
  setSaveLoading(state, status) {
    state.saveLoading = status;
  },
  archive(state, id){
    state.lists = [...state.lists.map(_ => {
      if (_.dbc_listing_id === id){
        _.is_archive = true;
      }
      return _;
    })]
  },
  unArchive(state, id){
    state.lists = [...state.lists.map(_ => {
      if (_.dbc_listing_id === id){
        _.is_archive = false;
      }
      return _;
    })]
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
/*
import { API } from 'aws-amplify';
import awsCongfig from '../aws-exports';

API.configure(awsCongfig);

const getApiUrlByName = (apiName) => {
  const apiParams = awsCongfig.aws_cloud_logic_custom.find(({ name }) => name === apiName);
  if (apiParams) {
    return apiParams.endpoint;
  }

  console.error(`No Api with name ${apiName}`);
  return '';
}

export { awsCongfig, getApiUrlByName };
*/

class BaseApi {

  constructor() {
    // this.Api = API;
  }

}

export default BaseApi;
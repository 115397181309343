import FeedsApi from '../../api/feeds';
// initial state
const state = () => ({
  list: undefined,
  feed: undefined,

  loading: false,
  saveLoading: false,
});


// getters
const getters = {};

// actions
const actions = {
  async loadFeeds({ commit, state }, agentId) {
    commit('setLoading', true);
    if (!state.list) {
      const list = await FeedsApi.loadFeedsByAgentId(agentId);
      commit('setList', list);
    }
    commit('setLoading', false);
  },
  async loadFeed({ commit, state, dispatch }, { agentId, feedId }) {
    commit('setLoading', true);
    if (!state.list) {
      await dispatch('Feed/loadFeeds', agentId, { root: true })
    }


    commit('setFeed', state.list.find(f => f.id === feedId));
    commit('setLoading', false);
  },
  async saveFeed({ commit, dispatch }, { agentId, feedId, feed }) {
    commit('setSaveLoading', true);

    const newFeedData = await FeedsApi.saveFeed(agentId, feedId, feed);
    commit('replaceFeed', newFeedData);

    dispatch('loadFeed', { agentId, feedId });

    commit('setSaveLoading', false);
  },

  async duplicateFeed({ commit, state }, { agentId, feedId }){
    commit('setSaveLoading', true);

    const feed = state.list.find(f => f.id === feedId);

    const newFeedData = await FeedsApi.addFeed(agentId, feed);

    commit('addFeed', newFeedData);

    commit('setSaveLoading', false);
  },

  async removeFeed({ commit, state }, { agentId, feedId }){
    commit('setSaveLoading', true);

    await FeedsApi.removeFeed(agentId, feedId);

    const list = state.list.filter(f => f.id !== feedId);

    commit('setList', list);

    commit('setSaveLoading', false);
  }
};

// mutations
const mutations = {
  setList(state, newList) {
    state.list = newList;
  },
  setFeed(state, feed) {
    state.feed = feed;
  },
  addFeed(state, feed) {
    if (!state.list){
      state.list = [];
    }
    state.list.push(feed);
  },
  replaceFeed(state, newFeed) {
    if (state.list) {
      const list = state.list.map(f => {
        if (f.id === newFeed.id) return newFeed;
        return f;
      });

      state.list = list;
    }
  },
  setLoading(state, status) {
    state.loading = status;
  },
  setSaveLoading(state, status) {
    state.saveLoading = status;
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
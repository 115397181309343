import BaseService from '@/services/BaseService';
import { GatewayType } from '@/services/ApiClient';

class MarketingService extends BaseService {
  constructor() {
    super(GatewayType.PORTAL);
  }


  marketList(list){
    localStorage.setItem(process.env.VUE_APP_MARKETING_LOCAL_STORAGE_KEY, JSON.stringify(list));
  }

  async loadMarketingTable(){
    try {
      const response = await this.apiClient.get('/MyPropertiesModal/Get');
      return response;
    }catch(e){
      console.error('Marketing data load err', e);
      throw e;
    }

  }
}

export default new MarketingService();

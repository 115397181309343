<template>
  <b-card no-body class="w-100" style="padding: 10px; height: 127px">
    <b-card-body class="p-1 d-flex">
      <div style="height: 100%; min-width: 150px">
        &nbsp;
        <img
          :src="photoUrl"
          class="card-img-top"
          style="height: 102px; width: 150px"
        />
      </div>
      <div class="w-100" style="margin-left: 16px">
        <b-row class="m-0">
          <b-col cols="12" class="">
            <b-row>
              <b-col class="p-0">
                <p class="cart-type m-0 text-left">{{ list.property_type }}</p>
              </b-col>
              <b-col class="price card-price">
                <p class="font-weight-bold m-0 text-right">
                  {{
                    new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                    }).format(list.list_price || 0)
                  }}
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-row>
                  <p class="card-address text-left m-0">
                    {{ list.street_address }},
                  </p>
                </b-row>
                <b-row style="margin-bottom: 4px">
                  <b-col class="p-0">
                    <p class="card-address text-left m-0">
                      {{ list.state }}, {{ list.zip }}
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <p style="padding-left: 3px" class="card-status m-0">
                    {{ getFullStatus(list.status) }}
                  </p>
                </b-row>
              </b-col>

              <b-col
                class="text-right"
                style="display: flex; justify-content: flex-end"
              >
                <div style="margin-top: auto">
                  <b-button
                    class="px-3 py-2 font-weight-bold btn-primary"
                    style="font-size: 14px !important"
                    @click="openMarketWnd"
                    variant="primary"
                    >Market
                  </b-button>
                  <b-button
                    class="px-3 py-2 ml-2 font-weight-bold btn-primary-outlined bg-white"
                    style="font-size: 14px !important"
                    @click="openDetailsWnd"
                    >Details
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { getFullStatus } from "@/services/MLService";

export default {
  props: {
    list: Object,
  },
  name: "PropertyTableItem",
  methods: {
    getFullStatus,
    openMarketWnd() {
      this.$emit("on-market", this.list);
    },
    openDetailsWnd() {
      this.$emit("on-details", this.list);
    },
  },
  computed: {
    photoUrl() {
      const [thumb = ""] = this.list.photos.map((_) => _.thumb);
      return thumb;
    },
  },
};
</script>

<style scoped lang="scss">
.my-lists-main-wrapper .card {
  padding: 0px 13px 10px 13px;
  justify-content: space-around;
  border-radius: 0 !important;
}

#carousel-0 > img {
  height: 190px;
}

.card-img-top {
  object-fit: cover;
  object-position: center;
}

.card-text {
  line-height: 24px;
  // padding: 9px 14px 0 14px;
}

.card-top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .cart-type {
    font-size: 0.875rem;
    margin-bottom: 12px;
  }
}

.card-address,
.card-state-zip {
  font-size: 1.125rem;
}

.card-price {
  font-size: 1.6rem;
}

.price {
  margin-left: 20px;
}

.card-status {
  margin-top: 12px;
  font-size: 0.75rem;

  &::before {
    content: "";
    height: 7px;
    width: 7px;
    transform: rotate(45deg);
    background: #525151;
    position: relative;
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 1px;
  }
}

.card-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;

  button {
    font-size: 1.6rem;
    flex-basis: 46.5%;
    font-weight: 700;
  }
}

.card-address,
.card-state-zip,
.card-price {
  line-height: 1.5rem;
}
</style>

import dbcapi from '../../lib/dbcapi';


// initial state
const state = () => ({
  agent: undefined,

});


// getters
const getters = {
  getAgent(state) {
    const { agent } = state;
    return agent;
  },
}

// actions
const actions = {
  async initAgentData({ commit }, newAgent) {
    const authData = dbcapi.auth();
    if (newAgent) {
      authData.office = newAgent.office;
      authData.company = newAgent.company;
      commit('setAgent', authData);
    } else {
      commit('setAgent', authData);
    }
  },

};

// mutations
const mutations = {
  setAgent(state, newAgentData) {
    state.agent = newAgentData;
  },
  setLoading(state, status) {
    state.loading = status;
  },
  setSaveLoading(state, status) {
    state.saveLoading = status;
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
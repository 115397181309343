
// initial state
const state = () => ({
  promptData: {
    prompt_offer: 'Order 200+ mailed listed/sold postcards & receive the forst 100 postcards for free!',
    prompt_label: 'PROMPT™',
    prompt_offer_internal: 'Offer Internal !!!',
    site_phone: '123-45-67',
  },

  loading: false,
  saveLoading: false,
});


// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  setOffer(state, newOffer) {
    state.offer = newOffer;
  },
  setLoading(state, status) {
    state.loading = status;
  },
  setSaveLoading(state, status) {
    state.saveLoading = status;
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
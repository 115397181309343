import axios from 'axios';
import dbcapi, { authToken } from '../lib/dbcapi';

export const GatewayType = {
  PORTAL: 'PORTAL',
  MLS: 'MLS',
}

const GatewayParamsByTypes = {
  [GatewayType.PORTAL]: { baseURL: process.env.VUE_APP_BASE_PORTAL_GATEWAY },
  [GatewayType.MLS]: { baseURL: process.env.VUE_APP_GATEWAY_MLS_DATA_URL, token: process.env.VUE_APP_GATEWAY_MLS_API_TOKEN },
}

class ApiClient {
  constructor(source = GatewayType.PORTAL) { // GatewayType
    this._validateSource(source);

    const token = authToken();
    const authData = dbcapi.auth();
    const { baseURL } = GatewayParamsByTypes[source];
    let { token: xApiToken } = GatewayParamsByTypes[source];
    if (source === GatewayType.PORTAL) xApiToken = authData.gatewayToken;
    this.axiosConfig = {
      headers: { 'Authorization': `Bearer ${token}`, 'x-api-key': xApiToken },
      baseURL,
    };
  }

  _validateSource(source) {
    if (!Object.keys(GatewayType).includes(source)) throw new Error('Please provide valid data source');
  }

  async get(url) {
    const { data } = await axios.get(url, this.axiosConfig);
    return data;
  }

  async post(url, data = {}) {
    return await axios.post(url, data, this.axiosConfig);
  }

  updateConfig(props) {
    this.axiosConfig = { ...this.axiosConfig, ...props };
  }

}

const apiClientInstances = {}; // Record<GatewayType, ApiClient>

export const getApiClientBySource = (source) => {
  if (!(source in apiClientInstances)) {
    apiClientInstances[source] = new ApiClient(source)
  }

  return apiClientInstances[source];
}

export default ApiClient;
import BaseService from './BaseService';
import { GatewayType } from '@/services/ApiClient';
import moment from 'moment-mini';


const PhotoTypes = {
  FULL: 'Full',
  THUMB: 'Thumb',
}

const StatusesMap = {
  'A': 'Active',
  'P': 'Pending',
  'C': 'Contingent'
}

export const getFullStatus = (status) => {
  if (status in StatusesMap) return StatusesMap[status];

  return null;
}


class MLService extends BaseService {
  constructor() {
    super(GatewayType.MLS);
  }

  async importListing(listId, userId){
    await this.apiClient.post('/matchListingToUser', { 'listingId': listId, 'userId': userId });
  }

  async changeArchiveListStatus(listId, status) {
    await this.apiClient.post('/archiveListing', { 'listingId': listId, 'archive': status });
  }

  async loadDetails(listingId, feedId) {
    const { data: { listing } } = await this.apiClient.post(
      '/getListingDetail', {feedId, listingId});

    return listing;
  }

  async advanceSearch(addressSearchString = '', feedId) {
    let listings = [];

    ({ data: { data: { listings = [] } = {} } = {} } = await this.apiClient.post(
      '/searchListingsByAddress', {
        addressSearchString,
        feedId,
      }));
    return listings;

  }


  async loadListsByAgentId(agentId) {
    const { data: { data: { listings: items } } } = await this.apiClient.post('/getListings',
                                                                    { userId: agentId });

    if (items && Array.isArray(items)) {
      let list = items.map((list) => {

        const { media: { photos } = { photos: [] } } = list;

        let gPhotos = [];

        photos.forEach(({ sequence: i, size, url }) => {
          if (!(+i in gPhotos)) gPhotos[+i] = {};
          const _photo = gPhotos[+i];
          if (size === PhotoTypes.FULL) _photo.src = url;
          if (size === PhotoTypes.THUMB) _photo.thumb = url;
          _photo.pk = i;
          _photo.sk = _photo.src.substring(url.lastIndexOf('/') + 1);
        })

        gPhotos = gPhotos.filter(_ => _);
        list.photos = gPhotos;
        list.thumb = (gPhotos[0]) ? (gPhotos[0].thumb || gPhotos[0].src) : '';

        return list;
      })

      list = this.autoArchiveListings(list);

      list = list.sort((d1, d2) => {
        return Date.parse(d2.list_date) - Date.parse(d1.list_date);
      })

      return list;
    }
  }

  autoArchiveListings(listings) {
    const archiveQueue = [];
    const { VUE_APP_AUTO_ARCHIVE_YEARS: autoArchYears = 1 } = process.env;
    listings.forEach((list) => {
      const {
            sold_date: soldDate,
            list_date: listDate,
            dbc_listing_id: id,
            is_archive: isArchived,
            non_expiring: nonExpiring,
      } = list;

      if (isArchived) return;

      let diffY = 0;
      if (soldDate || listDate) {
            diffY = moment().diff(moment(soldDate || listDate), 'years');
      } else {
            diffY = autoArchYears;
      }
      if (diffY >= autoArchYears && !nonExpiring) {
            archiveQueue.push(this.changeArchiveListStatus(id, true));
            list.is_archive = true;
      }
    });

    if (archiveQueue.length) {
      Promise.all(archiveQueue).then();
    }

    return listings;
  }

}


export default new MLService();

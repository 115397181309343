import BaseApi from '@/api/base-api';

class ListApi extends BaseApi {
  async loadListsByAgentId() { // agentId
    return Promise.resolve([
                             {
                               id: '1',
                               propphotourl: 'https://hv-list-images193654-dmgdevmgn.s3.us-west-2.amazonaws.com/2/960x0.jpg',
                               propertyid: 'RFGCB103010344-0021000096',
                               propstatus: 'Active',
                               proptitle: '',
                               propaddr: '1 3218 Homedale Road 3-4',
                               propcity: 'Klamath Falls',
                               propstate: 'OR',
                               propzip: '97603',
                               propprice: '$255,000',
                               propbed: '2',
                               propbath: '2',
                               propsqft: '1,920',
                               propdesc: 'Very nice 2 Bedroom, 1 1/2 bath, duplex located in the heart of Klamath Falls, This lovely duplex features, attached garages, a balcony, fenced back yards, open kitchen/dining area, upgraded bathrooms, extra parking space out front, washer/dryer hookups, Owners are licensed Oregon real estate brokers. Easy to show.',

                             },
                             {
                               id: '2',
                               propphotourl: 'https://hv-list-images193654-dmgdevmgn.s3.us-west-2.amazonaws.com/2/960x0.jpg',
                               propertyid: 'RFGCB103010344-0021000096',
                               propstatus: 'Active',
                               proptitle: '',
                               propaddr: '2 3218 Homedale Road 3-4',
                               propcity: 'Klamath Falls',
                               propstate: 'OR',
                               propzip: '97603',
                               propprice: '$255,000',
                               propbed: '2',
                               propbath: '2',
                               propsqft: '1,920',
                               propdesc: 'Very nice 2 Bedroom, 1 1/2 bath, duplex located in the heart of Klamath Falls, This lovely duplex features, attached garages, a balcony, fenced back yards, open kitchen/dining area, upgraded bathrooms, extra parking space out front, washer/dryer hookups, Owners are licensed Oregon real estate brokers. Easy to show.',

                             },
                             {
                               id: '3',
                               propphotourl: 'https://hv-list-images193654-dmgdevmgn.s3.us-west-2.amazonaws.com/2/960x0.jpg',
                               propertyid: 'RFGCB103010344-0021000096',
                               propstatus: 'Active',
                               proptitle: '',
                               propaddr: '3 3218 Homedale Road 3-4',
                               propcity: 'Klamath Falls',
                               propstate: 'OR',
                               propzip: '97603',
                               propprice: '$255,000',
                               propbed: '2',
                               propbath: '2',
                               propsqft: '1,920',
                               propdesc: 'Very nice 2 Bedroom, 1 1/2 bath, duplex located in the heart of Klamath Falls, This lovely duplex features, attached garages, a balcony, fenced back yards, open kitchen/dining area, upgraded bathrooms, extra parking space out front, washer/dryer hookups, Owners are licensed Oregon real estate brokers. Easy to show.',
                             },
                             {
                               id: '4',
                               propphotourl: 'https://hv-list-images193654-dmgdevmgn.s3.us-west-2.amazonaws.com/2/960x0.jpg',
                               propertyid: 'RFGCB103010344-0021000096',
                               propstatus: 'Active',
                               proptitle: '',
                               propaddr: '4 3218 Homedale Road 3-4',
                               propcity: 'Klamath Falls',
                               propstate: 'OR',
                               propzip: '97603',
                               propprice: '$255,000',
                               propbed: '2',
                               propbath: '2',
                               propsqft: '1,920',
                               propdesc: 'Very nice 2 Bedroom, 1 1/2 bath, duplex located in the heart of Klamath Falls, This lovely duplex features, attached garages, a balcony, fenced back yards, open kitchen/dining area, upgraded bathrooms, extra parking space out front, washer/dryer hookups, Owners are licensed Oregon real estate brokers. Easy to show.',
                             },
                             {
                               id: '5',
                               propphotourl: 'https://hv-list-images193654-dmgdevmgn.s3.us-west-2.amazonaws.com/2/960x0.jpg',
                               propertyid: 'RFGCB103010344-0021000096',
                               propstatus: 'Active',
                               proptitle: '',
                               propaddr: '4 3218 Homedale Road 3-4',
                               propcity: 'Klamath Falls',
                               propstate: 'OR',
                               propzip: '97603',
                               propprice: '$255,000',
                               propbed: '2',
                               propbath: '2',
                               propsqft: '1,920',
                               propdesc: 'Very nice 2 Bedroom, 1 1/2 bath, duplex located in the heart of Klamath Falls, This lovely duplex features, attached garages, a balcony, fenced back yards, open kitchen/dining area, upgraded bathrooms, extra parking space out front, washer/dryer hookups, Owners are licensed Oregon real estate brokers. Easy to show.',
                             },
                           ]);
  }
}


export default new ListApi();
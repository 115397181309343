<template>
  <b-modal :id="id" static ok-disabled size="lg" hide-footer  header-bg-variant="danger" header-text-variant="light" v-model="isShow" :title="title" @close="isShow=false">

    <template #default>
      <b-row v-if="!loading && marketingData.length">
        <b-col>
          <h4 class="main-header font-weight-bold m-0 px-5">What would you like to create?</h4></b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="align-content-center text-center w-100" v-if="loading">
          <b-spinner style="width: 4rem; height: 4rem; font-weight: bold;" />
          </div>
          <div v-html="marketingData"/>

        </b-col>
        <!--
        <b-col v-for="option in options" :key="option.title" cols="6">
          <b-button variant="secondary" @click="handleClickMarket" class="w-100 rounded-0 market-option d-flex align-items-center mt-3">
            <div class="w-100 text-center">
              <div class="modal-icon-container">
                <i class="modal-icon" :class="`imsicon-${option.icon}`"  />
              </div>
              <p class="align-middle w-100 text-capitalize text-center">{{ option.title }}</p>
            </div>  
          </b-button>
        </b-col>
        -->
      </b-row>
<!--
      <b-row class=" ml-modal-browse-btn">
        <b-col class="text-center">
          <b-button variant="secondary">Browse for something else</b-button>
        </b-col>
      </b-row>
      -->
    </template>

  </b-modal>
</template>

<script>

import MarketingService from '@/services/MarketingService';
import { showErrorMessage } from '@/utils/Notification';

export default {
  name: 'MarkedModal',
  props: {
    item: Object,
    id: String,
    show: Boolean,
  },
  data() {
    return {
      isShow: this.show,
      /*
      options: [
        { title: '!jumbo postcard 8.5X5.5', icon: 'jljs' },
        { title: '!flyer 8.5X11', icon: 'broHor' },
        { title: '!homeval featuring a property', icon: 'jljs' },
        { title: '!Other', icon: 'jljs' }
      ],

       */
      marketingData: '',
      loading: false,
    }
  },
  computed: {
    title() {
      return `Market: ${this.item.street_address}, ${this.item.city}, ${this.item.state} ${this.item.zip}`;
    },
  },
  watch: {
    async isShow(newValue) {
      if (!newValue) {
        this.$emit('onClose');
      } else {
        if (!this.marketingData.length) {
          this.loading = true;
          try {
            this.marketingData = await MarketingService.loadMarketingTable();
          }catch (e){
            showErrorMessage("Marketing data load error");
          }finally {
            this.loading = false;
          }

        }
      }
    },
    show(newValue) {
      this.isShow = newValue;
    },
  },
  methods: {
    handleClickMarket(){
      // TO-DO: redirect to marketing url
    }
  }
}
</script>

<style scoped>
.market-option {
  height: 173px;
  width: 100%;
  background: #E9ECEF;
}

.modal-icon-container {
  margin-bottom: 10px; 
}

.modal-icon-container .modal-icon{
  font-size:23px;
}


</style>
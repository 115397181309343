import Vue from 'vue'
const translations = {
};


import VueI18n from 'vue-i18n'

// here
Vue.use(VueI18n)
// call with I18n option
export const i18n = new VueI18n({
                             legacy: false,
                             locale: 'en',
                             fallbackLocale: 'en',
                             messages: {
                                 en: { },
                             },

                         });


let langpref = 'en';

const getSupportedLanguages = () => {
    return [{
        k: 'en',
        v: 'English'
    }, {
        k: 'es',
        v: 'Español'
    }, {
        k: 'fr',
        v: 'Français'
    }];
}

const t = (l,p) => {
    if (p) langpref = p;
    if (langpref == 'en') return l;
    try {
        if (translations[l][langpref] && translations[l][langpref]!='')
            return translations[l][langpref];
    } catch (e) {
        // console.log(l,p,e);
    }
    return l;
};
const setLang = (l) => {
    langpref = l;
};
export default {
    t,
    setLang,
    getSupportedLanguages
}

import Vue from 'vue';
import Vuex from 'vuex';

import Lists from './modules/list';
import Agent from './modules/agent';
import Prompt from './modules/prompt';
import Feed from './modules/feed';
import Auth from './modules/auth';

Vue.use(Vuex);

const state = {
}

const mutations = {
}

const debug = process.env.NODE_ENV !== 'production';


export default new Vuex.Store({
                                state,
                                mutations,
                                modules: {
                                  Lists,
                                  Agent,
                                  Prompt,
                                  Feed,
                                  Auth,
                                },
                                strict: debug,
                                devtools: debug,
                              });
<template>
  <style-tag>
    {{ style }}
  </style-tag>
</template>

<script>
import ThemeService from "@/services/ThemeService";
import StyleTag from "@/components/StyleTag";

export default {
  name: "ThemeStyle",
  components: { StyleTag },
  props: {
    wrapperPrefix: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      cssVariables: {}
    };
  },
  computed: {
    style() {
      if (!this.cssVariables) return "";

      const list = Object.keys(this.cssVariables)
        .map(v => `\n --${v}: ${this.cssVariables[v]}`)
        .join(";");

      const cssVars = `

      #properties_app {
            ${list}
      }`;

      return cssVars;
    }
  },
  created() {
    this.cssVariables = ThemeService.loadThemeVariablesFromStorage();
    const _self = this;
    ThemeService.loadThemeVariables().then(cssVariables => {
      _self.cssVariables = cssVariables;
    });
  }
};
</script>

<style scoped></style>

import BaseService from '@/services/BaseService';
import { GatewayType } from '@/services/ApiClient';

let cachedThemeVariables = null;

class ThemeService extends BaseService {
  constructor() {
    super(GatewayType.PORTAL);
    this.lsCacheKey = 'my_gallery_theme_widget_vars';
  }

  async loadThemeVariables(){
    if (cachedThemeVariables) return cachedThemeVariables;

    try {
      cachedThemeVariables = await this.apiClient.get('/WidgetTheme/Style');
      window.localStorage.setItem(this.lsCacheKey, JSON.stringify(cachedThemeVariables));
    }catch(e){
      console.error('Cannot load widget theme');
      // cachedThemeVariables = {};
      return this.loadThemeVariablesFromStorage();
    }
    return cachedThemeVariables;
  }

  loadThemeVariablesFromStorage(){
    const cachedVars = window.localStorage.getItem(this.lsCacheKey);
    if (cachedVars) return JSON.parse(cachedVars);

    return null;
  }

}

export default new ThemeService();
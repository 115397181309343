import { createToastInterface } from 'vue-toastification';


const pluginOptions = {
  timeout: 4000,
};


// Create the interface
const toast = createToastInterface(pluginOptions);

const showSuccessMessage = (text) => toast.success(text);
const showErrorMessage = (text) => toast.error(text);
const showInfoMessage = (text) => toast.info(text);
const showWarningMessage = (text) => toast.warning(text);



export {
  showSuccessMessage, showErrorMessage, showInfoMessage, showWarningMessage,
};

<template>
  <b-modal
      :id="id"
      modal-class="mp-additional-info"
      size="md"
      static
      ok-disabled
      header-bg-variant="danger"
      header-text-variant="light"
      v-model="isShow"
      :title="`${ list.street_address }, ${ list.city }, ${ list.state } ${ list.zip }`"
      @close="isShow=false"
      header-class="mp-aim-header"
      footer-class="mp-aim-footer"
      body-class="p-0"
      content-class="border-0"
  >

    <b-container fluid class="p-0">

      <div class="card h-100 m-0 p-0 border-0" style="">

        <div class="w-100 property-photo">
          <b-carousel
              id="carousel-1"
              v-model="slider"
              :interval="4000"
              controls
              indicators
              img-height="300px"
              img-width="100%"

          >
            <b-carousel-slide v-for="p in list.photos" class="prop-slider-img" :key="p.thumb"
                              :img-src="p.thumb"></b-carousel-slide>
          </b-carousel>
        </div>

        <div class="w-100 px-4 py-2 d-flex align-items-center">
            <font-awesome-icon icon="info-circle" class="info-icon" />  
            <p class="small-text m-0 pl-2 pt-1">
              These details come directly from your MLS source. <b>You will be able to edit these details during the personalization process.</b>
            </p>
        </div>
        

        <!-- <div class="property-image-badge">
          <b-badge class="rounded-0 float-left" variant="success"><h6 class="text-capitalize m-0 p-1 pl-2 pr-2 ">
            {{ fullStatus }}</h6>
          </b-badge>

          <b-badge class="rounded-0 float-left text-light" style="top: 50px" variant="secondary"><h6
              class="text-uppercase m-0 p-1 pl-2 pr-2 ">
            {{ property.property_type }}</h6>
          </b-badge>
        </div> -->


        <div class="card-body p-0 mp-aim-data mt-3" style="background: #E9E9E9">
          <feature-box :item="list" v-if="list.features" />
          <b-row class="mt-0 p-3" v-if="loading">
            <b-col cols="12" class="text-center">
              <b-spinner />
            </b-col>
          </b-row>

          <!-- <b-row class="mt-0 p-3" v-if="list.remarks && !loading">
            <b-col cols="12">

              <p class="main-header font-weight-bold" style="text-decoration: underline">Description</p>
              <div style="max-height: 120px; height: 110px; overflow: auto; width: 100%;">
              <p class="description mb-0" >{{ list.remarks }}</p>
              </div>

            </b-col>
          </b-row> -->
        </div>
      </div>

        <div cols="12" class="px-4 mp-aim-description mt-3">
              <h4>Description</h4>

              <p class="property-type text-left">{{ property.property_type }}</p>
              <div style="max-height: 120px; height: 110px; overflow: auto; width: 100%;" v-if="list.remarks && !loading">
                <p class="property-description text-left" >
                  {{ list.remarks }}
                </p>
              </div>
        </div>
    </b-container>

    <template #modal-footer>  
        <b-container fluid="">
          <b-row class="" v-if="list.list_date || list.sold_date">
          
            <b-col cols="6" class="d-flex justify-content-center">
              <p v-if="list.list_date" class="main-header font-weight-bold pr-0 text-align-center">Listing Date</p>
              <p v-if="list.list_date" class="pl-2">{{ list.list_date | formatDate }}</p> 
            </b-col>

             <b-col cols="6" class="d-flex justify-content-center">
              <p class="main-header font-weight-bold pr-0 text-align-center">Sold Date</p>
              <p class="pl-2" v-if="list.sold_date">{{ footerDate | formatDate }}</p>
              <p class="pl-2" v-else>Unavailable</p>
            </b-col>

          </b-row>
          
        </b-container>
      </template>
    </b-modal>
  </template>

<script>
import FeatureBox from '@/components/Listings/FeatureBox';
import { mapState } from 'vuex';
import { getFullStatus } from '@/services/MLService';

export default {
  name: 'PropertyDetailsModal',
  components: { FeatureBox },
  props: {
    property: Object,
    show: Boolean,
    id: String,
    onClose: Function,
  },
  data() {
    return {
      isShow: false,
      slider: 0,
      list: {}
    }
  },
  computed: {
    ...mapState({
                  loading: state => state.Lists.loadingDetails,
                  // list: state => state.Lists.list,
                }),

    fullStatus() {
      return getFullStatus(this.list.status);
    },


  },
  watch: {
    isShow(newValue) {
      if (!newValue) {
        this.$emit('onClose');
      } else {
        this.list = this.property;
        /*
        // constellation call is disabled now, will be used .remarks field instead
        this.$store.dispatch('Lists/loadListDetails',
                             { feedId: this.property.feed_id, listingId: this.property.dbc_listing_id }, { root: true })

         */
      }
    },
    show(newValue) {
      this.isShow = newValue;
      this.slider = 0;
    },
  },
  filters: {
    formatDate: d => new Date(d).toLocaleString().replace('-', '/').split(',')[0],
  },
}
</script>

<style scoped lang="scss">
  
.action-w-100-px {
  width: 100px
}

.property-image-badge {
  position: absolute;
  top: 245px;
  left: 0px;
  border-radius: 0 !important;
}

.property-photo {
  background-position: center;
  background-repeat: no-repeat;
  height: 351px;
  background-size: cover;
}

.description {
  line-height: 100%;
  font-size: 0.75rem;
}

.prop-slider-img {
  padding: 0 1.5rem;
}

.modal .modal-header.mp-aim-header {
  border-bottom: none !important;
}

.small-text {
  font-size: 0.875rem;
}

.info-icon {
      font-size: 1.7rem;
}

.mp-aim-data *{
  font-size: 1.125rem;
}

.property-type::before {
    content: "";
    height:8px;
    width:8px;
    transform: rotate(45deg);
    background: #525151;
    position: relative;
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 1px;
}

.property-description {
  font-size: 1.125rem;
}

</style>
<template>
  <b-container fluid class="p-0">
    <theme-style />
    <div
      id=""
      ref="content"
      style="position: relative; height: 100%; margin-top: 0px"
    >
      <PropertyTableItem
        v-for="item in list"
        :key="item.dbc_listing_id"
        :list="item"
        @on-market="openMarketWnd"
        @on-details="openDetailsWnd"
      />
    </div>

    <MarkedModal
      :item="activeProperty"
      @onClose="isMarketShow = false"
      :show="isMarketShow"
      id="market-property-wnd"
    />

    <PropertyDetailsModal
      :property="activeProperty"
      @onClose="isDetailsShow = false"
      :show="isDetailsShow"
      id="prop-details-wnd"
    />
  </b-container>
</template>

<script>
import PropertyTableItem from "./PropertyTableItem";
import MarketingService from "../../services/MarketingService";
import ThemeStyle from "../ThemeStyle";

import { mapState } from "vuex";
import MarkedModal from "../Listings/MarketModal";
import PropertyDetailsModal from "@/components/Listings/PropertyDetailsModal";

export default {
  components: {
    PropertyTableItem,
    MarkedModal,
    PropertyDetailsModal,
    ThemeStyle,
  },
  data() {
    return {
      isMarketShow: false,
      isDetailsShow: false,
      activeProperty: {},
      // activeList: []
    };
  },
  props: {
    list: { type: Array, default: () => [] },
  },
  computed: {
    ...mapState({
      loading: (state) => state.Lists.loading,
      lists: (state) => state.Lists.lists,
      agentLoading: (state) => state.Agent.loading,
      agent: (state) => state.Agent.agent,
    }),
  },

  name: "PropertiesTable",
  methods: {
    openMarketWnd(list) {
      this.activeProperty = list;
      this.isMarketShow = true;
      MarketingService.marketList(list);
    },
    openDetailsWnd(list) {
      this.activeProperty = list;
      this.isDetailsShow = true;
    },
  },
};
</script>

<style scoped></style>

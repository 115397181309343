import BaseService from './BaseService'
import dbcapi from '@/lib/dbcapi';
import { GatewayType } from '@/services/ApiClient';

class AgentService extends BaseService {
  constructor() {
    super(GatewayType.MLS);
    this.apiClient.updateConfig({ headers: { 'x-api-key': process.env.VUE_APP_GATEWAY_MLS_API_TOKEN } });
  }

  async getAgentIdByEmail(email) {
    try {
      const {
        data: { data: { agent_id: agentId } = {} } = {},
      } = await this.apiClient.post('/getAgentByEmail', { email });
      return agentId;
    } catch (e) {
      return null;
    }
  }

  async loadUserData() {
    try {
      const userData = await dbcapi.getAuthUser();

      console.log('user', userData);
      return userData;

    } catch (e) {
      console.log('getAuthUser error', e);
    }
    return null;
  }
}

export default new AgentService();
const sampledata = [
    {
        "id": "agent-002100-0101",
        "sort": "AG-20200102-1",
        "gallery_type": "AG",
        "gallery_id": "1",
        "name": "my first gallery",
        "created_at": "20200102"
    },
    {
        "id": "AG-1",
        "sort": "photo-20200502-4",
        "src": "https://ims-galleries.s3.us-west-1.amazonaws.com/AG/0000/002100/0096/00000972.jpg",
        "created_at": "20200502",
        "source_system": "Upload"
    },
    {
        "id": "ML-remax-01234",
        "sort": "photo-20200302-1",
        "src": "https://ims-galleries.s3.us-west-1.amazonaws.com/AG/0000/002100/0096/00000970.jpg",
        "created_at": "20200302",
        "source_system": "REMAX MLS"
    },
    {
        "id": "office-002100",
        "sort": "OF-20200106-3",
        "gallery_type": "OF",
        "gallery_id": "3",
        "name": "around town",
        "created_at": "20200106"
    },
    {
        "id": "company-2100",
        "sort": "CO-20200109-4",
        "gallery_type": "CO",
        "gallery_id": "4",
        "name": "nice company photos",
        "created_at": "20200109"
    },
    {
        "id": "agent-002100-0101",
        "sort": "AG-20200102-2",
        "gallery_type": "AG",
        "gallery_id": "2",
        "name": "my second gallery",
        "created_at": "20200102"
    },
    {
        "id": "agent-002100-0101",
        "sort": "ML-20200602-remax-01234",
        "gallery_type": "ML",
        "gallery_id": "remax-01234",
        "name": "123 main, anytown",
        "created_at": "20200602"
    },
    {
        "id": "ML-remax-01234",
        "sort": "photo-20200812-2",
        "src": "https://ims-galleries.s3.us-west-1.amazonaws.com/AG/0000/002100/0096/00000971.jpg",
        "created_at": "20200812",
        "source_system": "REMAX MLS"
    },
    {
        "id": "ML-remax-01234",
        "sort": "info",
        "gallery_type": "ML",
        "mls_id": "01234",
        "mls_source": "remax",
        "prop_address": "123 main",
        "prop_city": "anytown",
        "prop_state": "ca",
        "json": "{\"random\":\"\",\"incoming\":\"\",\"data\":\"\"}",
        "agent_owner": "002100-0101",
        "created_at": "20200812"
    },
    {
        "id": "AG-1",
        "sort": "photo-20200111-3",
        "src": "https://ims-galleries.s3.us-west-1.amazonaws.com/AG/0000/002100/0096/00000980.jpg",
        "created_at": "20200111",
        "source_system": "Upload"
    },
    {
        "id": "office-002100",
        "sort": "OF-20200113-5",
        "gallery_type": "OF",
        "gallery_id": "5",
        "name": "local food",
        "created_at": "20200113"
    },
    {
        "id": "company-2100",
        "sort": "CO-20200515-6",
        "gallery_type": "CO",
        "gallery_id": "6",
        "name": "national strength",
        "created_at": "20200515"
    },
    {
        "id": "AG-1",
        "sort": "info",
        "gallery_type": "AG",
        "agent_owner": "002100-0101",
        "created_at": "20200302"
    },
    {
        "id": "CO-4",
        "sort": "info",
        "gallery_type": "CO",
        "created_at": "20200109"
    },
    {
        "id": "CO-6",
        "sort": "info",
        "gallery_type": "CO",
        "created_at": "20200515"
    },
    {
        "id": "OF-3",
        "sort": "info",
        "gallery_type": "OF",
        "created_at": "20200106"
    },
    {
        "id": "OF-5",
        "sort": "info",
        "gallery_type": "OF",
        "created_at": "20200113"
    }
];

// const test_agent_galleries = [
//     {
//         id: 1, gallery_name: '123 Main', gallery_type: 'AG', photos: [
//             {
//                 id: 1,
//                 src: 'https://ims-galleries.s3.us-west-1.amazonaws.com/AG/0000/002100/0096/00000972.jpg',
//                 height: 2,
//                 width: 3
//             },
//             {
//                 id: 3,
//                 src: 'https://ims-galleries.s3.us-west-1.amazonaws.com/AG/0000/002100/0096/00000970.jpg',
//                 height: 2,
//                 width: 3
//             },
//             {
//                 id: 4,
//                 src: 'https://ims-galleries.s3.us-west-1.amazonaws.com/AG/0000/002100/0096/00000971.jpg',
//                 height: 2,
//                 width: 3
//             }
//         ]
//     },
//     {
//         id: 2, gallery_name: '123 Anytown', gallery_type: 'AG', photos: [
//             {
//                 id: 5,
//                 src: 'https://ims-galleries.s3.us-west-1.amazonaws.com/AG/0000/002100/0096/00000980.jpg',
//                 height: 2,
//                 width: 3
//             },
//             {
//                 id: 6,
//                 src: 'https://ims-galleries.s3.us-west-1.amazonaws.com/AG/0000/002100/0096/00000987.jpg',
//                 height: 2,
//                 width: 3
//             }
//         ]
//     }
// ];

const user = () => {
    return {
        "user": {
            "Username": "nf",
            "UserGuid": "16ac7394-6705-42b0-9a19-1cbc92419c15",
            "UserId": 503099,
            "CompanyId": 1429,
            "SiteId": 57,
            "FirstName": "Nikola",
            "Name": "Nikola Filipovic",
            "LastName": "Filipovic",
            "FullName": "Nikola Filipovic",
            "Active": true,
            "ProjectRevisionAccess": true,
            "EmailDistr": false,
            "BillingAccess": false,
            "RfqModuleAccess": false,
            "UserTitle": "",
            "DefaultLogo": "",
            "ExtIdentifier": "",
            //"ExternalId": "100053624",
            "DivisionName": "",
            "Title2": "",
            "Email": "peter@meyerrealty.net",
            "CCEmail": "systemcatch@deluxebrand.com",
            "Address1": "",
            "Address2": "",
            "Address3": "",
            "InvoiceEmail": "nikola.filipovic@deluxe.com",
            "PaperInvoice": false,
            "UserGroupName": null,
            "Photo": "https://uat-portal.deluxebrand.com/static/customerimages/1429/1429_503099_user_photo.jpg",
            "City": "Phoenix",
            "ZipCode": "",
            "AdminAccess": true,
            "PowerBuyer": true,
            "Fax": "",
            "AllowBulkOrder": true,
            "OrderPlaced": true,
            "IsStore": false,
            "OrderShipped": true,
            "PhoneNumber": "0000000000",
            "PhoneNumber2": "",
            "PhoneNumber3": "",
            "CompanyName": null,
            "Custom01": "",
            "Custom02": "",
            "Custom03": "",
            "Custom04": "",
            "Custom05": "",
            "Custom06": "",
            "Custom07": "",
            "Custom08": "",
            "Custom09": "",
            "Custom10": "",
            "IsSSOUser": false,
            "IsGenericUser": false,
            "SpendingLimit": 0,
            "Website": "",
            "StateId": 3368,
            "CountryId": 219,
            "State": {
                "StateId": 3368,
                "Name": "Arizona",
                "StateCode": "AZ",
                "Country": {
                    "CountryId": 219,
                    "CountryName": "United States",
                    "CountryCode": "US",
                    "DateCreated": null,
                    "CreatedBy": null,
                    "DateModified": null,
                    "ModifiedBy": null
                }
            },
            "AccountingUnitId": null,
            "UserGroupId": 6714,
            "ApprovingManagerId": null,
            "DefaultPriceListId": 13884,
            "DefaultLoginUrl": "/login/dbcarch",
            "Password": null,
            "ConfirmPassword": null,
            "DateCreated": "2020-08-12T08:55:12.393",
            "DateModified": "2021-01-06T11:44:30.61",
            "UserBalance": 425698.58,
            "Settings": {
                "ShowNewBasedOnField": 0,
                "<NewShowsForDays>k__BackingField": 0,
                "ShowUpdatedBasedOnField": 0,
                "<UpdatedShowsForDays>k__BackingField": 0,
                "<ConsumerViewRights>k__BackingField": 0,
                "<FooterHtml>k__BackingField": null,
                "<UsergroupLandingSetting>k__BackingField": 0,
                "<ShippingAddressLocked>k__BackingField": false,
                "<BillingAddressLocked>k__BackingField": false,
                "<DefaultCompanyFilters>k__BackingField": 0,
                "<HiddenTypes>k__BackingField": [],
                "<IsTaxable>k__BackingField": false,
                "<VerifyAddress>k__BackingField": false,
                "<CanEditUserInfo>k__BackingField": false,
                "<CustomUrlPath>k__BackingField": "dbcarch",
                "<ApprovalMessage>k__BackingField": "",
                "<InvoiceMessage>k__BackingField": "",
                "<InclusionTags>k__BackingField": [
                    "5u9GVUMVwCZas27d"
                ],
                "<ExclusionTags>k__BackingField": [],
                "<ShowApprovalWorflowWillTriggerAlert>k__BackingField": false,
                "<IsProofNameLocked>k__BackingField": false,
                "<IsCXmlPunchOut>k__BackingField": false,
                "<ReturnUrl>k__BackingField": null,
                "<ApprovalWorkflowIds>k__BackingField": [],
                "<SaveAccountingUnitsToOrderLine>k__BackingField": false,
                "<GoogleAnalyticsCode>k__BackingField": null,
                "<GoogleTagManagerCode>k__BackingField": null,
                "<ThemeId>k__BackingField": 841,
                "<CanShipOnWeekends>k__BackingField": false,
                "<ShowPartLimits>k__BackingField": false,
                "<IsAdmin>k__BackingField": true,
                "<AllowImpersonateUser>k__BackingField": false,
                "<IsAcmSuperAdmin>k__BackingField": false,
                "<IsDesiredShipDateVisible>k__BackingField": true,
                "<ShowAdditionalPartData>k__BackingField": false,
                "<IsEstimateShipDateVisible>k__BackingField": true,
                "<IsPriceInformationVisible>k__BackingField": false,
                "<PriceListId>k__BackingField": 13884,
                "<IsInventoryInformationVisible>k__BackingField": false,
                "<IsMaxOrderQtyVisible>k__BackingField": false,
                "<ApprovalWorkFlowType>k__BackingField": 83,
                "<CompanyTimeInterval>k__BackingField": 0,
                "<ShowEstimatedShippingCost>k__BackingField": false,
                "<ShowPartCategoryThumbnails>k__BackingField": false,
                "<IsFreightOnlyBilling>k__BackingField": false,
                "<IsNewPartsGroupVisible>k__BackingField": false,
                "<IncludeCustomFields>k__BackingField": true,
                "<ShowConsumerModule>k__BackingField": false,
                "<HideShippingAddress>k__BackingField": false,
                "<AllowPriceEdit>k__BackingField": false,
                "<AllowSystemDollar>k__BackingField": false,
                "<DefaultUserId>k__BackingField": 504155,
                "<IsEcommerceCodeRequired>k__BackingField": false,
                "<EcommerceAccessCode>k__BackingField": "",
                "<IsFastPickPack>k__BackingField": false,
                "<WarehouseId>k__BackingField": 99,
                "<CanStopOrders>k__BackingField": false,
                "<AllowPromoCodes>k__BackingField": false,
                "<CustomLandingPageUrl>k__BackingField": null,
                "<CustomActPageId>k__BackingField": 1304,
                "<ShowShippingInFurtureAlert>k__BackingField": false,
                "<ShippingInFurtureAlertDays>k__BackingField": 0,
                "<IsCrossSaleItemsVisible>k__BackingField": false,
                "<ApprovalOnAllOrders>k__BackingField": false,
                "<ApprovalOnOrderQtyPerDayRange>k__BackingField": false,
                "<ApprovalOnAu>k__BackingField": false,
                "<ApprovalOnPartThreshhold>k__BackingField": false,
                "<ApprovalOnSpendingLimit>k__BackingField": false,
                "<BypassApprovalOnCC>k__BackingField": false,
                "<IsRetailUser>k__BackingField": false,
                "<CompanyLogo>k__BackingField": "/static/customerimages/1429/57_1429_company_logo.png",
                "<IsAssetUploadRestricted>k__BackingField": false,
                "<IsUserGroupManager>k__BackingField": false,
                "<AccountingUnitId>k__BackingField": 0,
                "<IsPowerBuyer>k__BackingField": true,
                "<ShowBillingAddress>k__BackingField": true,
                "<ShowHighResProof>k__BackingField": false,
                "<ExpiringSoonDays>k__BackingField": 0,
                "<ViewSkuOnBrowse>k__BackingField": false,
                "<ViewCallToActionOnBrowse>k__BackingField": false,
                "<ViewSecondaryNameOnBrowse>k__BackingField": false,
                "<DefaultOrderStatus>k__BackingField": 82,
                "<IsAlwaysRequireShippingAddress>k__BackingField": false,
                "<IsAlwaysRequireBillingAddress>k__BackingField": false,
                "<UseImagelistDefault>k__BackingField": false,
                "<ShowInvoiceOption>k__BackingField": false,
                "<AllowSavedCreditCards>k__BackingField": false,
                "<ShowPasswordInRegistrationEmail>k__BackingField": false,
                "<IsApprovalWorkflow>k__BackingField": false,
                "<InvoiceFee>k__BackingField": 0,
                "<InvoiceEmail>k__BackingField": null,
                "<AllowUserManagement>k__BackingField": false,
                "<UseAvalaraTax>k__BackingField": false,
                "<CompanyTaxId>k__BackingField": "",
                "<AvalaraConnectorId>k__BackingField": null,
                "<AllowAdditionalOrderFiles>k__BackingField": false,
                "<EnforcePersonaInstanceCreation>k__BackingField": false,
                "<AllowExistingList>k__BackingField": true,
                "<OverrideEstimatedShipping>k__BackingField": false,
                "<AllowCorporateList>k__BackingField": false,
                "<CurrencyDecimalFormat>k__BackingField": "c2",
                "<ShowDownloadLowRes>k__BackingField": true,
                "<CompanyEdeliveryPrice>k__BackingField": 0,
                "<CompanyPasswordExpiration>k__BackingField": 90,
                "<FlagShowImageResolutionWarning>k__BackingField": true,
                "<EnableShowBackorderAlert>k__BackingField": false,
                "<IsCompanyNameRequiredOnCheckout>k__BackingField": false,
                "<IsPhoneNumberRequiredOnCheckout>k__BackingField": false,
                "<AddressVerificationMessage>k__BackingField": "",
                "<EnableAccessibilityWidget>k__BackingField": false,
                "<HideEmptyPartCategories>k__BackingField": false,
                "<UseBraintree>k__BackingField": false,
                "<PortalGuid>k__BackingField": "8d243ef4-3430-4ef1-8f48-c8b57fb1c239",
                "<ShippingCountryId>k__BackingField": null,
                "<AmmTenant>k__BackingField": "DB",
                "<ViewApprovalMessaging>k__BackingField": false,
                "<IsProofNameRequired>k__BackingField": false,
                "<ShowToggleFilters>k__BackingField": false,
                "<EnforceUserPersonaCompleting>k__BackingField": false,
                "<TermsAndConditionsAgreementActive>k__BackingField": false,
                "<TermsAndConditionsAgreementRequired>k__BackingField": false,
                "<OpenPersonalizeFormDuringReorder>k__BackingField": false,
                "<UserGroup>k__BackingField": "Super Admin",
                "<ViewProductOverlay>k__BackingField": false,
                "<ActUserType>k__BackingField": null,
                "<CustomActPage>k__BackingField": null,
                "<AllowUserDelegates>k__BackingField": false,
                "<AllowOnlyDelegatesOfSameUserGroup>k__BackingField": false,
                "<NewProductPageUx>k__BackingField": false,
                "<Jwt>k__BackingField": null
            },
            "UserGroup": {
                "CompanyId": 1429,
                "UserGroupId": 6714,
                "GroupName": "Super Admin",
                "DisplayName": "",
                "GroupDescription": "Ehh :)",
                "IsActive": true,
                "IsRetail": false,
                "AllowSystemDollar": false,
                "IsTaxable": false,
                "PaymentCoOpOnly": false,
                "PaymentBillLaterAllowed": true,
                "PaymentPurchaseOrderAllowed": true,
                "PaymentCreditCardAllowed": true,
                "FlagSalesTaxType": 0,
                "FlatSalesTaxRate": null,
                "TimeInterval": 0,
                "ManagerName": "",
                "LimitQty": 999999,
                "UserGroupBudget": 0,
                "IsPublic": false,
                "AvailableBudget": 0,
                "ManagementType": 0,
                "EnForceMaxMinOrderQuantites": false,
                "ShippingAddressLocked": false,
                "BillingAddressLocked": false,
                "IncludeCustomFields": true,
                "ShippingDay": "",
                "ApprovalWorkFlowType": "S",
                "IgnoreSsoUsergroupChange": false
            },
            "InclusionTags": null,
            "InclusionArray": [],
            "ExclusionTags": null,
            "ExclusionArray": [],
            "AccessCode": null,
            "OpenStoreFrontUserId": 0,
            "IsPriceAdmin": true,
            "AllowImpersonateUser": false,
            "IsDynamicGroupAccess": false,
            "Country": {
                "CountryId": 219,
                "CountryName": "United States",
                "CountryCode": "US",
                "DateCreated": null,
                "CreatedBy": null,
                "DateModified": null,
                "ModifiedBy": null
            },
            "MovePhotoFromTemp": false,
            "MoveLogoFromTemp": false,
            "HasAvailableBillingAddresses": false,
            "CartLines": null,
            "AccountLocked": false
        }
    };
};

const data = () => {
    return sampledata;
};

export default {
    data, user
}

import BaseApi from '@/api/base-api';

class FeedsApi extends BaseApi {
  async saveFeed(agentId, feedId, feed){
    console.log('API SAVE FEED', feed);

    return Promise.resolve(feed);
  }

  async removeFeed(agentId, feedId){
    console.log('API REMOVE FEED', feedId );

    return Promise.resolve('OK');
  }

  async addFeed(agentId, feed){
    console.log('API ADD FEED', feed );
    feed = {...feed, id: `${Math.random()}`};
    return Promise.resolve(feed);
  }

  async loadFeedsByAgentId(agentId) {
    console.log('API LOAD FEEDS BY agent', agentId);
    return Promise.resolve([
                             {
                               id: '1',
                               company: 'Portal 1',
                               is_active: 'Y',
                               label: 'REMAX MLS ABC, Inc.',

                               auth_type: '',
                               remote_mls_name: '',
                               mls_source_name: 'ABCMLLS',
                               remote_mls_id_field_name: '',
                               remote_agent_id_field_name: '',
                               remote_office_id_field_name: '',
                               companies: '2100,2200',
                               feed_is_new_records_only: false,
                               mark_existing_deleted_if_missing_from_feed: false,
                               create_orders: true,
                               order_handler: 'default',
                               email_handler: 'default',
                               match_on_handler: 'default',
                               prompt_offer: '200_50_400_100',
                               special_text: 'More text<br>here',
                               test_csv: '',
                               listing_load: 'Y',
                               agent_match: 'Y',
                               photo_load: 'Y',
                               send_emails: 'Y',
                               listing_import_handler: 'default', //  | remax_xml1
                               photo_import_handler: 'default', // | remax_xml1
                               sends_per_listing: '1',
                               sends_per_sale: '1',
                               resend_1_if_no_order: 'Y',
                               resend_1_if_no_order_after_days: '7 | 0',
                               email_offices_only: '002101,002201',
                               email_offices_exclude: '',
                               all_emails_to_address: 'a@b.co',
                               email_forwarding: 'a@b.co:c@d.co,e@f.co:g@h.co',
                               email_copy_to_office_admin: 'Y',
                               email_copy_to_office_admin_if_new_listing: 'N',
                               max_user_emails_per_day: '1',
                               max_user_emails_per_week: '5',
                               product_1_label: '1 qwqwqwq',
                               product_1_image: '',
                               product_1_link: '',
                               product_2_label: '2 qwqwassdasd',
                               product_2_image: '',
                               product_2_link: '',
                               product_3_label: '3 sdfsdfsdf',
                               product_3_image: '',
                               product_3_link: '',
                               product_4_label: '',
                               product_4_image: '',
                               product_4_link: '',
                               additional_config: {},
                             },
                             {
                               id: '2',
                               company: 'Portal 2',
                               is_active: 'N',

                               auth_type: '',
                               remote_mls_name: '',
                               mls_source_name: 'ABCMLLS',
                               remote_mls_id_field_name: '',
                               remote_agent_id_field_name: '',
                               remote_office_id_field_name: '',
                               companies: '2100,2200',
                               feed_is_new_records_only: false,
                               mark_existing_deleted_if_missing_from_feed: false,
                               create_orders: true,
                               order_handler: 'default',
                               email_handler: 'default',
                               match_on_handler: 'default',
                               prompt_offer: '200_50_400_100',
                               special_text: 'More text<br>here',
                               test_csv: '',
                               listing_load: 'Y',
                               agent_match: 'Y',
                               photo_load: 'Y',
                               send_emails: 'Y',
                               listing_import_handler: 'default', //  | remax_xml1
                               photo_import_handler: 'default', // | remax_xml1
                               sends_per_listing: '1',
                               sends_per_sale: '1',
                               resend_1_if_no_order: 'Y',
                               resend_1_if_no_order_after_days: '7 | 0',
                               email_offices_only: '002101,002201',
                               email_offices_exclude: '',
                               all_emails_to_address: 'a@b.co',
                               email_forwarding: 'a@b.co:c@d.co,e@f.co:g@h.co',
                               email_copy_to_office_admin: 'Y',
                               email_copy_to_office_admin_if_new_listing: 'N',
                               max_user_emails_per_day: '1',
                               max_user_emails_per_week: '5',
                               product_1_label: '',
                               product_1_image: '',
                               product_1_link: '',
                               product_2_label: '',
                               product_2_image: '',
                               product_2_link: '',
                               product_3_label: '',
                               product_3_image: '',
                               product_3_link: '',
                               product_4_label: '',
                               product_4_image: '',
                               product_4_link: '',
                               additional_config: {},
                             },
                           ]);
  }
}


export default new FeedsApi();
import AuthService from '../../services/AgentService';
import { isProd, isUat } from '@/services/EnvService';
import { showErrorMessage } from '@/utils/Notification';

// initial state
const state = () => ({
  agent: { agentid: '' },
  agentId: null,
  loading: false,
  team: true,
  sampleLink: 0,
  saveLoading: false,
});


// getters
const getters = {
  getAgent(state) {
    const { agent } = state;
    return agent;
  },

  getAgentExternalId(state) {
    if (state.agentId) return state.agentId;

    if (state.agent && state.agent.ExternalId) {
      return state.agent.ExternalId;
    }

    if (isUat() && process.env.VUE_APP_DEMO_EXT_ID) {
      return process.env.VUE_APP_DEMO_EXT_ID;
    }

    return '';
  },
}

// actions
const actions = {
  async loadAgentData({ commit, dispatch }) {
    commit('setLoading', true);
    try {
      const agent = await AuthService.loadUserData();

      // Load agentId by Email
      const { Email: email } = agent;
      if (email) {
        const agentId = await AuthService.getAgentIdByEmail(email);
        console.log('Agent Id By Email', agentId);
        if (agentId === null && isProd()) {
          showErrorMessage('Cant get AgentId by Email');
        } else {
          commit('setAgentId', agentId);
        }
      }


      console.log('Agent Data', agent);
      commit('setAgent', agent);
      dispatch('Auth/initAgentData', agent, { root: true });
    } catch (e) {
      console.error('agent error', e)
    } finally {
      commit('setLoading', false);
    }
  },
};

// mutations
const mutations = {
  setAgent(state, newAgentData) {
    state.agent = newAgentData;
  },
  setAgentId(state, newAgentId) {
    state.agentId = newAgentId;
  },
  setLoading(state, status) {
    state.loading = status;
  },
  setSaveLoading(state, status) {
    state.saveLoading = status;
  },
  setSampleLink(state, newLink) {
    state.sampleLink = newLink;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
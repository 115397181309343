<template>
  <b-row class="modal-bg-secondary m-0 pt-3 pb-3" style="">

    <b-col style="display: flex; justify-content: space-evenly;">
      <div v-for="e in features" :key="e.id" class="text-nowrap text-center p-0" :class="e.value ? '' : 'd-none'">

        <template v-if="e.value">
          <span v-if="e.id !== 'building_sq_ft'" class="main-header font-weight-bold">{{ e.value }}</span>
          <span v-else class="main-header font-weight-bold">{{ new Intl.NumberFormat('en-US').format(e.value) }}</span>

          {{ $options.displayKeys[e.id].label }}
        </template>

      </div>
      <div class="text-nowrap text-center" v-if="item.list_price">
      <span class="font-weight-bold main-header">{{
          new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(
              item.list_price)
        }}</span>
        Listing Price
      </div>

    </b-col>
  </b-row>

</template>

<script>

const findById = (id, list) => {
  return list.find(_ => _.id === id);
}

const DisplayKeys = {
  beds: {
    label: 'Beds',
    id: 'beds',
  }, baths_total: {
    label: 'Baths',
    id: 'baths_total',
  }, building_sq_ft: { id: 'building_sq_ft', label: 'Sq.Ft.' },
}

export default {
  name: 'FeatureBox',
  props: {
    item: Object,
  },
  displayKeys: DisplayKeys,
  computed: {
    features() {
      if (!this.item || !this.item.features || !Array.isArray(this.item.features)) return [];
      const { features = [] } = this.item;

      return Object.keys(DisplayKeys).map(key => findById(key, features)).filter(Boolean);
    },
  },
}
</script>

<style scoped>

</style>
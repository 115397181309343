import Vue from "vue";
import PropertiesTable from "./PropertiesTable.vue";
// import router from "./router";
import store from "./store";
import ToggleButton from "vue-js-toggle-button";
import vueDropzone from "vue2-dropzone";
import VueI18n from "vue-i18n";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faInfoCircle,
  faUndoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

Vue.use(VueI18n);

library.add(faSearch, faInfoCircle, faUndoAlt);

Vue.component("font-awesome-icon", FontAwesomeIcon);

import { i18n } from "./lib/i8n";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

// const options = {
// You can set your default options here
// };

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

Vue.use(ToggleButton);
Vue.use(vueDropzone);
Vue.component("vue-dropzone", vueDropzone);

// Install BootstrapVue
Vue.use(BootstrapVue);

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

import "vue2-dropzone/dist/vue2Dropzone.min.css";

Vue.config.productionTip = false;
Vue.config.devtools = true;

store.dispatch("Auth/initAgentData", null, { root: true });

new Vue({
  i18n,
  // router,
  store,
  render: (h) => h(PropertiesTable),
  props: { activeTab: String },
  mounted() {
    console.log("mounted");
    // console.log(this.activeTab);
  },
}).$mount("#properties_app");

// import order from './order';
import sampleproduct from './sampleproduct';
import sampledata from './sampledata';
import axios from 'axios';


const inLocal = () => {
  if (location.href.indexOf('/localhost') != -1 || location.href.indexOf('127.0.0') != -1) return true; else return false;
};
const inUat = () => {
  if (location.href.indexOf('/uat-portal.') != -1) return true; else return false;
};
const inProd = () => {
  if (!inUat() && location.href.indexOf('com/Store/Browse') != -1) return true; else return false;
};
export const authToken = () => {
  let s = '';
  try {
    s = window.localStorage.getItem('token');
  } catch (e) {
    console.log('error trying to get token', e);
  }
  if (!s) s = 'abc';
  return s;
};

const authUser = () => {
  let s = '';
  try {
    if (inLocal()) s = '002100-0101';
    s = window.localStorage.getItem('dbcUserGuid');
    if (userData && userData.UserId) s = userData.UserId;
  } catch (e) {
    console.log('error trying to get user', e);
  }
  if (!s) s = '002100-0101';
  return s;
};
const authOffice = () => {
  let s = '002100';
  if (inLocal()) s = '002100';
  if (userData && userData.UserGroupId) s = userData.UserGroupId;
  // TODO office id
  // console.log('Office ID not implemented');
  if (!s || s == '') s = '002100';
  return s;
};
const authCompany = () => {
  let s = '';
  try {
    if (inLocal()) s = '2100';
    s = window.localStorage.getItem('dbcCompanyGuid');
  } catch (e) {
    console.log('error trying to get company', e);
  }
  if (!s || s == '') s = '2100';
  return s;
};
const authClientName = () => {
  let s = '';
  try {
    if (inLocal()) s = 'km2';
    s = window.localStorage.getItem('dbcClientName');
  } catch (e) {
    console.log('error trying to get client name', e);
  }
  if (!s) s = '';
  return s;
};

// needs to return immediately
const auth = () => {
  // console.log('auth',process.env);
  let a = {
    token: authToken(),
    gatewayToken: process.env.VUE_APP_GATEWAY_TOKEN,
    gateway: process.env.VUE_APP_GATEWAY,
    userEndpoint: process.env.VUE_APP_GATEWAY_USER_DATA,
    galleryBucket: process.env.VUE_APP_AWS_GALLERY_BUCKET,
    galleryTable: process.env.VUE_APP_AWS_GALLERY_TABLE,
    // tokenDev: 'WWNxUmRTaVM3QWFGamZTaVFKNHV0M0VQMUpiSWNENlMzQmlOQU50UQ==',
    agent: authUser(),
    office: authOffice(),
    company: authCompany(),
    clientName: authClientName(),
    // userData: authUserData, // dont do api calls here, needs to return immediately
    env: (inUat() ? 'uat' : (inProd() ? 'prod' : 'local')),
  };
  authData = a;
  // console.log('auth',a);
  return a;
};

let userData = null; // use getAuthUser(cb) ; cached response
let authData = null;

const getAuthUser = async () => {

  if (userData) {
    return userData;
  }

  // const authUserData = async () => {
  if (!authData) auth();
  // const token = authToken();
  const headers = { 'Authorization': `Bearer ${authData.token}` };

  if (inLocal()) {
    let mockData = sampledata.user();
    userData = mockData.user;
    return userData;
  }
  try {
    const x = await axios.get(authData.userEndpoint, { headers });
    if (!x || !x.data || !x.data.user) {
      console.log('getAuthUser unexpected response', x);
      throw new Error('getAuthUser unexpected response');
    }
    userData = x.data.user;
    // refresh office,company id
    userData.office = x.data.office_usergroup_id;
    userData.company = x.data.company_usergroup_id;
    // refresh UserId, UserGroupId
    authData = auth();
    return userData;

  } catch (err) {
    console.log(err);
    // cb(err);
    throw err;
  }

};

let permissionsData = null;

const permissions = async () => {
  if (permissionsData) return permissionsData;
  if (!authData) auth();
  const headers = { 'Authorization': `Bearer ${authData.token}`, 'x-api-key': authData.gatewayToken };
  // TODO sk of real user - userData.UserId ?
  if (!userData) {
    throw new Error('permissions fail, user data unavailable');
  }
  console.log('permissions userData.agent', userData.UserId);
  try {
    const x = await axios.post(authData.gateway + '/gallery/admin', { md: 'get', pk: 'permission', sk: `${authUser()}` }, { headers });
    // console.log('permissions',x.data);
    if (x.data && x.data.data && x.data.data.json) {
      let j = JSON.parse(x.data.data.json);
      return j;
    }
    return { mls: false, office: false, company: false }; // ok there wont always be a perm record

  } catch (err) {
    console.log(err);
    // cb(err);
    throw err;
  }

};

// const testDevGateway = () => {
//   console.log('testDevGateway');
//   // const endpoint = 'https://qtvjo4adf6.execute-api.us-west-2.amazonaws.com/dbcdev/gallery/';
//   // const method = 'AG-1';
//   const method = 'test/ym/abc'; // list,remove,thumb
//   /// curl -XGET -H 'Authorization=Bearer YcqRdSiS7AaFjfSiQJ4ut3EP1JbIcD6S3BiNANtQ' "https://7n7l08yp06.execute-api.us-west-2.amazonaws.com/uat/gallery/test"
//   // let token = null;
//   // curl -v -H 'x-api-key: YcqRdSiS7AaFjfSiQJ4ut3EP1JbIcD6S3BiNANtQ' https://7n7l08yp06.execute-api.us-west-2.amazonaws.com/uat/gallery/test/abc/123
//   let authData = auth();
//   let token = authData.gatewayToken;
//   const endpoint = authData.gateway + '/gallery/';

//   axios.get(endpoint + method, {
//       headers:{
//         'x-api-key': token,
//         'Content-Type': 'application/json'
//           // 'Authorization': 'Bearer ' + token
//       }
//       // ,withCredentials: true
//   }).then((x)=>{
//       console.log(x);
//   }).catch((e)=>{
//       console.log('error',e);
//   });
// }


const getOrder = (orderid, cb) => {
  console.log('dbcapi.getOrder', orderid);
  let prod = null; // TODO dbc data
  setTimeout(() => { // fake load delay
    // TODO dbc order with product id that can be used to look up prod photos
    if (orderid == sampleproduct.orderId()) {
      prod = sampleproduct.product(); // {pid:'123'}
    }
    cb(null, prod);
  }, 1000);
};

const getProductPhotos = (pid, cb) => {
  console.log('dbcapi.getProductPhotos', pid);
  let photos = [];
  // TODO dbc data using pid
  setTimeout(() => {  // fake load delay
    photos = sampleproduct.productPhotos();
    cb(null, photos);
  }, 1000);
};

const getNextOrderFlowPage = (orderid, cb) => {
  console.log('dbcapi.getNextOrderFlowPage', orderid);
  let url = null; // TODO dbc data
  setTimeout(() => { // fake load delay
    url = sampleproduct.nextPage(orderid);
    cb(null, url);
  }, 1000);
};

export default {
  getOrder,
  getProductPhotos,
  getNextOrderFlowPage,
  getAuthUser,
  permissions,
  // testDevGateway,
  auth,
  inLocal,
  inUat,
  inProd,
}

